import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery, Link } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-4" key={post.id}>
              <Link to={post.fields.slug}>
                <article className="tile is-child" style={{overflow: 'hidden', backgroundColor: '#1E2126'}}>
                  <div
                    className="title is-size-5"
                    style={{ margin: '-8px -8px 0 -8px', display: 'block' }}>
                    { post.frontmatter.thumbnail ?
                      <PreviewCompatibleImage imageInfo={post.frontmatter.thumbnail} />
                      : null
                    }
                  </div>
                  <div style={{ padding: 8*4 }}>
                    <p className="is-size-6-mobile is-size-5" style={{marginBottom: 8, display: 'block', color: '#FF0077'}}>
                      {post.frontmatter.title}
                    </p>
                    <p className="has-text-grey-lighter is-size-6-mobile is-size-5">
                      {post.excerpt}
                    </p>
                  </div>
                </article>
              </Link>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 72)
              id
              fields {
                slug
              }
              frontmatter {
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
